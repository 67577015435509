import { inAppWebView } from 'Classes/ConfigurationManager';
import { serializeError } from 'serialize-error';

export const postMessage = (options) => {
  if (inAppWebView()) {
    const data = typeof options === 'object' ? {
      ...options,
      ...(localStorage.getItem('token') && { token: localStorage.getItem('token') })
    } : options;

    let mockData = typeof data === 'object' ? { ...serializeError(data) } : data;
    if (data.token) mockData.token = '***';
    mockData = JSON.stringify(mockData);

    const postMessageFn = window.ReactNativeWebView?.postMessage || window.postMessage;
    if (typeof postMessageFn === 'function') {
      console.info(postMessageFn === window.ReactNativeWebView?.postMessage ? 'ReactNativeWebView postMessage' : 'postMessage', mockData);
      postMessageFn.call(window, mockData, '*');
    } else {
      console.error('postMessage not available');
    }
  }
};

export const redirectWebApp = (options) => {
  if (options) postMessage(options);
  postMessage('closeWebView');
};

export const loginInApp = () => {
  postMessage({});
};

export default {
  computed: {
    isInAppWebView() {
      return inAppWebView();
    },
    isInFacebookApp() {
      const ua = navigator.userAgent || navigator.vendor;
      return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
    },
  },

  methods: {
    postMessage,
    redirectWebApp,
    loginInApp,
  },
};
